.login-screen {
  text-align: center;
  background-color: #282c34;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.login-header {
  /* font-size: calc(10px + 2vmin); */
  margin-bottom: 16px;
  letter-spacing: 1.5px;
}
.login-subheader {
  /* font-size: calc(10px + 2vmin); */
  margin-bottom: 36px;
  letter-spacing: 1.5px;
}
.login-error {
  color: rgb(216, 0, 0);
  font-style: italic;
}

.login-form {
  flex-basis: 33%;
  width: 200px;
  border: 1px solid rgb(167, 167, 167);
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 12px;
}
