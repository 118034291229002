.dashboard-screen {
  text-align: center;
  background-color: #282c34;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}
.video-container {
  background: black;
  display: inline-block;
  line-height: 0;
  max-width: 100%;
  width: 50%;
}

video {
  width: 100%;
}
